<template>
    <div class="action-area py-6 px-8">
        <SwipeDragButton
            handler-icon="keyboard_double_arrow_right"
            :background="buttonInfo.color"
            :progress-bar-bg="buttonInfo.color"
            :completed-bg="buttonInfo.color"
            :handler-bg="buttonInfo.handlerColor"
            :width="buttonWidth"
            :height="60"
            text-size="24px"
            radius="32px"
            :text="buttonInfo.text"
            @passcallback="swipeHandler"
            ref="SwipeDragButton">
        </SwipeDragButton>
    </div>
</template>

<script>
import {bus} from '@/main';
import {flagdownMixin} from '@/mixins/flagdownMixin.js';
import {getBookingTransactions, takeCardPayment, takeCashPayment, updateBookingAmount} from '@/services/BookingService.js';
import {handleDoublePaymentError} from '../utils/sentryErrors';
import {haversineDistance} from '../utils/helper';
import {recheckMobileState} from '@/services/PostMessageService';
import {resumeMeter} from '@/services/MeterService';
import {setBookingArrived, setBookingAsCompleting, setBookingComplete, setBookingInProgress, setBookingNextStop, setBookingOnRoute} from '@/services/StatusService.js';
import {showActionsDialog, showErrorDialog, showSuccessDialog, showWarningDialog} from '@/services/DialogService';
import {stopWaiting} from '@/services/WaitService';
import SwipeDragButton from '@/components/SwipeDragButton';

export default {
    name: 'BookingStatusButton',
    props: {
        appData: {
            type: Object,
        },
        booking: {
            type: Object,
            default: () => {},
        },
        isWaiting: {
            type: Boolean,
            required: true,
        },
        waitPointId: {
            type: String,
        },
        editedAmount: {
            type: Number,
        },
        isLoading: {
            type: Boolean,
            required: true,
        },
        bookingCompleteConfirmation: {
            type: Boolean,
            required: true,
        },
        useLocalMeter: {
            type: Boolean,
        },
        localMeter: {
            type: Object,
        },
        syncMeterOnServer: {
            type: Function,
        },
    },
    components: {
        SwipeDragButton,
    },
    mixins: [flagdownMixin],
    data() {
        return {
            buttonWidth: 300,
            cardPaymentFailureCount: 0,
            completionPaymentSuccessful: false,
        };
    },
    methods: {
        async swipeHandler() {
            try {
                if (this.isLoading) return;

                const invalidReason = this.verifyStatusChange();
                if (invalidReason && invalidReason.message) {
                    showErrorDialog(null, invalidReason.message, 'Confirm', invalidReason.title, invalidReason.customClass);
                    if (this.$refs.SwipeDragButton) this.$refs.SwipeDragButton.reset();
                    return;
                }

                bus.$emit('loading', true);
                if (window.navigator.vibrate) {
                    navigator.vibrate(200);
                }

                const {BookingStatus, BookingStops, Id, NextStop, CompletingDateTime, MeterPaused, FlagDown, PaymentCollectionMode, PaymentMethod, CardPaymentStatus, _grandTotal} = this.booking || {};
                let clearLocalWaiting = false;
                if (BookingStatus === 'Allocated') {
                    await setBookingOnRoute(Id);
                } else if (BookingStatus === 'OnRoute') {
                    await setBookingArrived(Id);
                } else if (BookingStatus === 'Arrived') {
                    await setBookingInProgress(Id);
                    bus.$emit('clearArrivalWaiting', false);
                    clearLocalWaiting = true;
                } else if (BookingStatus === 'InProgress' && !CompletingDateTime) {
                    if (this.isWaiting) {
                        await stopWaiting(this.booking.Id, this.waitPointId);
                        bus.$emit('stopManualTimer', false);
                        clearLocalWaiting = true;
                    }
                    // last stop; end journey
                    if (!NextStop || NextStop >= BookingStops.length) {
                        if (MeterPaused) {
                            await resumeMeter(Id);
                        }
                        if (this.useLocalMeter && this.syncMeterOnServer) {
                            await this.syncMeterOnServer();
                        }
                        await setBookingAsCompleting(Id);
                    } else {
                        // move to next stop
                        await setBookingNextStop(Id, NextStop + 1);
                    }
                } else if (BookingStatus === 'InProgress' && CompletingDateTime) {
                    if ((FlagDown && this.driverState.CanDriverAmendPriceOnFlagDown) || this.isExternalMeterBooking) {
                        if (this.priceUpdateRequired) {
                            await updateBookingAmount(Id, this.editedAmount);
                        }
                    }
                    if (PaymentCollectionMode && PaymentCollectionMode === 'Completion' && PaymentMethod === 'Card' && CardPaymentStatus != 'Success' && _grandTotal > 0) {
                        try {
                            await this.handleCardPayment();
                        } catch (err) {
                            await this.initiateAlternatePayment();
                        }
                    } else {
                        await setBookingComplete(Id);
                        this.booking.BookingStatus = 'Completed';
                        bus.$emit('bookingCompleted');
                    }
                } else if (BookingStatus === 'Completed') {
                    bus.$emit('journeyCompleted');
                }

                bus.$emit('fetchUpdatedBooking', clearLocalWaiting);
                if (this.$refs.SwipeDragButton) this.$refs.SwipeDragButton.reset();
                bus.$emit('openHome');

                return true;
            } catch (err) {
                bus.$emit('fetchUpdatedBooking');
                if (this.$refs.SwipeDragButton) this.$refs.SwipeDragButton.reset();
                showErrorDialog(err, 'Some Error occurred whilst updating booking status', 'Try Again');
                recheckMobileState();
            }
        },
        verifyStatusChange() {
            const {BookingStatus, CompletingDateTime, ArrivalDistanceInMeters, BookingStops, NextStop, CompletionDistanceInMeters} = this.booking;

            if (BookingStatus === 'OnRoute' && ArrivalDistanceInMeters != null) {
                const pickup = BookingStops && BookingStops[0];
                if (pickup && pickup.Latitude != null && pickup.Longitude != null) {
                    const location = {
                        latitude: pickup.Latitude,
                        longitude: pickup.Longitude,
                    };

                    if (!this.isLocationInRange(location, ArrivalDistanceInMeters)) {
                        return {
                            title: 'Cannot Mark Arrived',
                            message: 'Booking cannot be marked as "Arrived" because your location is outside the ideal radius of the pickup location.',
                        };
                    }
                }
            }

            if (BookingStatus === 'InProgress' && !CompletingDateTime && (!NextStop || NextStop >= BookingStops.length)) {
                if (this.enforceMeterEvents && !this.bookingCompleteConfirmation) {
                    return {
                        title: 'Journey Incomplete on Meter',
                        message: 'Please complete the journey on meter first. If your meter is disconnected please make sure it\'s connected and try to complete the journey again.',
                        customClass: {
                            htmlContainer: 'text-2xl',
                        },
                    };
                }

                if (CompletionDistanceInMeters != null) {
                    const dropOff = BookingStops && BookingStops.length > 1 ? BookingStops[BookingStops.length - 1] : null;
                    if (dropOff && dropOff.Latitude != null && dropOff.Longitude != null) {
                        const location = {
                            latitude: dropOff.Latitude,
                            longitude: dropOff.Longitude,
                        };

                        if (!this.isLocationInRange(location, CompletionDistanceInMeters)) {
                            return {
                                title: 'Cannot Complete Booking',
                                message: 'Booking cannot be marked as "Complete" because your location is outside the ideal radius of the drop location.',
                            };
                        }
                    }
                }
            }

            if (BookingStatus === 'InProgress' && CompletingDateTime) {
                if (this.editedAmount == null && this.isExternalMeterBooking) {
                    return {
                        title: 'Update Journey Cost',
                        message: 'Please update the journey cost before completing booking',
                    };
                }

                if (this.editedAmount <= 0 && this.isExternalMeterBooking && ['Card', 'OnAccount'].includes(this.booking.PaymentMethod)) {
                    return {
                        title: 'Invalid Amount',
                        message: 'Please enter amount greater than zero',
                    };
                }

                if (this.allowPriceEdit) {
                    if (this.editedAmount && this.editedAmount > 100) {
                        let estimateAmount = this.useLocalMeter ? this.localMeter.MeterCost : this.booking.ActualCost;
                        estimateAmount = estimateAmount ? estimateAmount * 2 : 200;
                        if (this.editedAmount > estimateAmount) {
                            return {
                                title: 'Amount Exceeds Limits',
                                message: 'The amount exceeds the billing limits, please verify and check again. If you believe this is in error, please contact office.',
                            };
                        }
                    }
                }

                if (this.booking.SignatureRequired && !this.booking.PassengerSignatureImageUrl) {
                    return {
                        title: 'Signature Required',
                        message: 'Please take passenger\'s signature before completing the booking.',
                    };
                }
            }

            return null;
        },
        isLocationInRange(location, threshold) {
            threshold = Number(threshold) / 1000; // convert to km
            const currentLocation = {
                latitude: this.appData.latitude || this.appData.lat,
                longitude: this.appData.longitude || this.appData.lon,
            };

            if (currentLocation.latitude == null || currentLocation.longitude == null) {
                return false;
            }

            const distance = haversineDistance(currentLocation, location);
            if (distance > threshold) {
                return false;
            }

            return true;
        },
        async handleCardPayment() {
            const existingTransactions = await getBookingTransactions(this.booking.Id);
            if (existingTransactions && existingTransactions.data) {
                const payment = existingTransactions.data.find(transaction => transaction.TransactionType === 'Payment' && transaction.Success === true);
                if (payment) {
                    showSuccessDialog('Payment already collected.', 'Confirm');
                    this.completionPaymentSuccessful = true;
                    bus.$emit('cardPaymentCompleted');
                    handleDoublePaymentError(`Double payment prevented. BookingId - ${this.booking.Id}. LocalId - ${this.booking.LocalId}.`);
                    return;
                }
            }
            await takeCardPayment(this.booking.Id);
            this.completionPaymentSuccessful = true;
            bus.$emit('cardPaymentCompleted');
            showSuccessDialog('Payment completed successfully.', 'OK');
        },
        async initiateAlternatePayment() {
            this.cardPaymentFailureCount = this.cardPaymentFailureCount + 1;
            if (this.cardPaymentFailureCount >= 3) {
                await takeCashPayment(this.booking.Id);
                bus.$emit('fetchUpdatedBooking');
                showWarningDialog('Collect Cash', 'After multiple card payment failures, this booking has been converted to CASH. Please collect cash from the passenger.', 'OK');
                return;
            }
            if (this.booking.AllowDriversToCollectPreauth && this.cardPaymentFailureCount === 1) {
                const preAuthAmount = await this.getPreAuthAmount();
                if (preAuthAmount && preAuthAmount < this.booking._grandTotal) {
                    this.matchPreAuthOption(preAuthAmount);
                    return;
                }
            }
            this.convertToCashOption();
        },
        matchPreAuthOption(preAuthAmount) {
            showActionsDialog(
                'Payment Collection Error',
                `<p class="text-2xl">
                    The payment of £${this.booking._grandTotal.toFixed(
        2
    )} for this booking has failed. However there is a <strong class="text-green-500"> pre-auth on hold for £${preAuthAmount.toFixed(2)}</strong>. You can choose the options below.
                </p>`,
                {
                    buttonText: `Collect £${preAuthAmount.toFixed(2)} & Update Fare`,
                    successTitle: 'Success',
                    successSubTitle: 'Booking amount updated and payment successfully collected.',
                    exec: async () => {
                        await this.collectPreAuthedAmount(preAuthAmount);
                    },
                    showSuccessDialog: true,
                    failureTitle: 'Payment Collection Error',
                    failureSubTitle: 'There was an error collecting the amount. Please try again.',
                    showErrorDialog: true,
                },
                'Retry Payment',
                true
            );
        },
        async collectPreAuthedAmount(preAuthAmount) {
            const currentBaseCost = this.allowPriceEdit && this.editedAmount ? this.editedAmount : this.useLocalMeter ? this.localMeter.MeterCost : this.booking.ActualCost;

            let currentTotal = this.booking._grandTotal;
            if (this.allowPriceEdit) {
                currentTotal = this.editedTotal;
            }

            const difference = currentTotal - preAuthAmount;

            await updateBookingAmount(this.booking.Id, currentBaseCost - difference / (1 + (this.booking.TaxRatio || 0)), false);
            await this.handleCardPayment();
            bus.$emit('fetchUpdatedBooking');
        },
        async getPreAuthAmount() {
            const transactions = await getBookingTransactions(this.booking.Id);
            if (transactions && transactions.data && transactions.data.length) {
                const preAuthCollection = transactions.data.find(
                    transaction => (transaction.TransactionType && transaction.TransactionType.toLowerCase()) === 'preauth' && transaction.Success === true
                );
                if (preAuthCollection) {
                    return Number(preAuthCollection.Amount);
                }
            }
            return null;
        },
        convertToCashOption() {
            showActionsDialog(
                'Payment Collection Error',
                'There was an error collecting the card payment for this booking. Please consider converting this booking to CASH which will allow you to collect an InCar terminal payment as well. To retry card payment press Cancel and try again.',
                {
                    buttonText: 'Convert To Cash',
                    successTitle: 'Success',
                    successSubTitle: 'Booking has been registered as Cash. Please proceed.',
                    exec: async () => {
                        await takeCashPayment(this.booking.Id);
                        bus.$emit('fetchUpdatedBooking');
                    },
                    showSuccessDialog: true,
                    failureTitle: 'Error',
                    failureSubTitle: 'There was an error updating the payment method to Cash. Please try again.',
                    showErrorDialog: true,
                }
            );
        },
        handleWindowResize() {
            this.buttonWidth = window.innerWidth - 64;
        },
        moveToCompleting() {
            const {BookingStatus, CompletingDateTime} = this.booking || {};
            if (BookingStatus === 'InProgress' && !CompletingDateTime) {
                this.swipeHandler();
            }
        },
        async moveToInProgress() {
            const {BookingStatus} = this.booking || {};
            if (BookingStatus === 'Arrived') {
                await this.swipeHandler();
            }
            if (BookingStatus === 'OnRoute') {
                const success = await this.swipeHandler();
                if (success) {
                    const unwatch = this.$watch(
                        'booking',
                        function () {
                            if (this.booking.BookingStatus === 'Arrived' && !this.isLoading) {
                                this.swipeHandler();
                                unwatch();
                            }
                        },
                        {deep: true}
                    );
                }
            }
        },
    },
    computed: {
        buttonInfo() {
            // default
            const button = {
                color: 'bg-green-500',
                text: 'Swipe on Arrival',
            };

            const {BookingStatus, BookingStops, NextStop, CompletingDateTime, PaymentCollectionMode, PaymentMethod, CardPaymentStatus, _grandTotal} = this.booking || {};
            if (BookingStatus === 'Allocated') {
                button.handlerColor = 'bg-allocated-dark';
                button.color = 'bg-allocated';
                button.text = 'Start Journey';
            } else if (BookingStatus === 'OnRoute') {
                button.handlerColor = 'bg-onroute-dark';
                button.color = 'bg-onroute';
                button.text = 'Swipe on Arrival';
            } else if (BookingStatus === 'Arrived') {
                button.handlerColor = 'bg-arrived-dark';
                button.color = 'bg-arrived';
                button.text = 'Swipe to Start';
            } else if (BookingStatus === 'InProgress' && !CompletingDateTime) {
                if (!BookingStops?.length) return button;
                button.handlerColor = 'bg-inprogress-dark';
                button.color = 'bg-inprogress';
                button.text = NextStop >= BookingStops.length ? 'Swipe to Payment' : 'Swipe to Next Stop';
            } else if (BookingStatus === 'InProgress' && CompletingDateTime) {
                if (PaymentCollectionMode && PaymentCollectionMode === 'Completion' && PaymentMethod === 'Card' && CardPaymentStatus != 'Success' && _grandTotal > 0) {
                    button.text = 'Charge Saved Card';
                    button.handlerColor = 'bg-completed-dark';
                    button.color = 'bg-completed';
                } else {
                    button.text = 'Swipe to Finalise';
                    button.handlerColor = 'bg-completed-dark';
                    button.color = 'bg-completed';
                }
            } else if (BookingStatus === 'Completed') {
                button.text = 'Swipe to Exit';
                button.handlerColor = 'bg-completed-dark';
                button.color = 'bg-completed';
            }
            return button;
        },
    },
    created() {
        bus.$on('moveToCompleting', this.moveToCompleting);
        bus.$on('moveToInProgress', this.moveToInProgress);
    },
    mounted() {
        this.buttonWidth = window.innerWidth - 64;
        window.addEventListener('resize', this.handleWindowResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.handleWindowResize);
        bus.$off('moveToCompleting', this.moveToCompleting);
        bus.$off('moveToInProgress', this.moveToInProgress);
    },
};
</script>
